.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.img-wrapper {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;
}

.shipment-container {
  margin-top: 25px;
  padding: 0 16px;
}

.shipment-id-wrapper, .trailer-id-wrapper {
  align-items: center;
  flex-direction: column;
  height: 50px;
}

.submit-container {
  margin-top: 25px;
}

.separator-title {
  font-size: 12px;
  margin: 12px 0;
}

.input-field {
  border-radius: 0.375rem;
  border: 1px solid rgba(200,211,222,1);
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  padding: 7px;
  color: rgba(57,70,87,1);
  width: 21rem;
  height: 1.5rem;
}

.btn {
  width: 358px;
  height: 44px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 32px 0 24px 0;
  cursor: pointer;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: solid 0.5px #fff;
  display: flex;
  justify-content: center;
}

.btnDisabled {
  background-color: #4242429a;
}

.btnActive {
  background-color: #0067a0;
}

.bg-blue {
  background-color: #e5f1fd;
}

.bg-white {
  background-color: #ffffff;
}

.dispaly-center {
  display: flex;
  justify-content: center;
}
