@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/roboto-400.woff2');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/roboto-500.woff2');
  font-weight: 500;
  font-display: block;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.roboto {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

.rect-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5%;
  align-items: center;
}

.helpdesk__phone {
  text-decoration: none;
  color: #024B71;
}