.club-closed__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(0, -10%);
}

.club-closed__text {
    margin-top: 36px;
    padding: 0 30px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}