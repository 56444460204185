.column {
  display: flex;
  flex-direction: column;
}

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.root {
  overflow: hidden;
}

.container::-webkit-scrollbar {
  display: none;
}

.container-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 33%;
  width: 100%;
  background-color: #0067a0;
  z-index: -1;
}

.logo {
  height: 4%;
}
