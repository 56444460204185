.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  width: 31rem;
  background-color: #fff;
}

.modal-header,
.modal-footer {
  padding: 1.5rem;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0067a0;
  color: #fff;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 2rem 1.25rem 1.25rem 1.25rem;
  overflow: auto;
  max-height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-full {
  width: 100vw;
  height: 100vh;
}

.close-icon {
  position: absolute;
  right: 0;
  margin-right: 1.5rem;
  cursor: pointer;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.mb-16 {
  margin-bottom: 1rem;
}
