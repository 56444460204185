.round-rectangle {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 39, 81, 0.15);
  border-radius: 8px;
  margin: 2rem 1.5rem 1.2rem 1.5rem;
  width: 85%;
}

.message-container {
  padding: 18px;
  gap: 2%;
}

.small-icon-container {
  background: #d9eaff;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-text {
  color: #414c52;
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 0;
  font-size: 1.1rem;
}

.subtitle-text {
  color: #024b71;
  font-weight: 400;
}

.description {
  color: #024b71;
  text-align: center;
}
