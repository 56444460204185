.list-container {
  margin-top: 0.5rem;
  text-align: center;
}

.can-item-container {
  padding-left: 0;
  margin: 0;
  display: inline-block;
  list-style-type: none;
}

.can-item {
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
  margin-right: 0.6rem;
  margin-bottom: 0.75rem;
}

.active-background {
  background: #0067a0;
  color: white;
  border: 2px solid #0067a0;
}

.inactive-background {
  background: white;
  color: #0067a0;
  border: 2px solid #bbc8d1;
}
