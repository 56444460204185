.language-settings {
  align-self: flex-end;
  align-items: center;
  gap: 7px;
  padding: 7% 5%;
}

.language {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 24px;
}

.color-white {
  color: white;
}

.color-blue {
  color: #0067A0
}

.button-with-no-border {
  border: none;
  background: none;
  padding: 0;
}
