.toast-container {
  display: flex;
  flex: 1;
  align-items: center;
  width: 85%;
  padding: 1rem;
  gap: 0.5rem;
  background: #024b71;
  border-radius: 0.25rem;
  position: absolute;
  bottom: 2%;
  z-index: 9999;
}

#slide {
  bottom: -50%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slide 0.5s forwards, slide-out 1s forwards;
  animation-delay: 0.5s, 5s;
}

@-webkit-keyframes slide {
  100% {
    bottom: 2%;
  }
}

@keyframes slide {
  100% {
    bottom: 2%;
  }
}

@keyframes slide-out {
  100% {
    bottom: -50%;
  }
}

@keyframes slide-out {
  100% {
    bottom: -50%;
  }
}

p {
  color: white;
  padding: 0;
  margin: 0;
  flex: 1;
}

.tick-container {
  background: #1da578;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
