.typography-regular {
  font-size: 1rem;
  line-height: 1.5rem;
}

.typography-medium {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.typography-large {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}
