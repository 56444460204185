.feedback {
  display: flex;
  flex-direction: column;
}

.feedback-title {
  text-align: center;
  padding-top: 5%;
}

.feedback-stars {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.star-wrapper {
  padding: 0.25rem;
  cursor: pointer;
}

.star-btn {
  background-color: transparent;
  border: none;
  outline: none;
}

.feedback-input-label {
  margin-bottom: 0.75rem;
  color: #024b71;
}

.feedback-input-textarea {
  width: 90%;
  height: 4.5rem;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 1rem 1rem;
  caret-color: #607480;
  resize: none;
}

.feedback-input-textarea:focus-visible {
  outline: none;
}

.feedback-input-textarea::placeholder {
  font-size: 1rem;
  font-weight: 400;
  color: #607480;
}

.feedback-reaction {
  color: #bf8921;
  text-align: center;
  margin: 0.5rem;
  display: inline-block;
  line-height: 0;
}

.fb-max-length {
  align-self: flex-end;
  margin: 0.5rem;
}

.fb-max-length.gray {
  color: #616160;
}

.fb-max-length.red {
  color: red;
}

/* Move Later */
.feedback-input {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
}

@media screen and (max-height: 300px) {
  #feedback-text{
      position: absolute; 
      width: 90%;
      top: 50px;
  }
}