.container-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 33%;
  width: 100%;
  background-color: #0067a0;
  z-index: -1;
}

.location-bubble {
  background: #d9eaff;
  height: 30%;
  padding: 2%;
  border-radius: 100%;
}

.info-icon {
  align-self: center;
  margin: 4%;
}

.button {
  padding: 0.75rem 1rem;
  position: fixed;
  bottom: 3%;
  width: 90%;
  background: #0067a0;
  border: 2px solid #007cb0;
  border-radius: 2rem;
  color: white;
}

.large-icon {
  align-self: center;
  margin: 8%;
  width: 16rem;
}

.action-button {
  padding: 0.75rem 1rem;
  position: fixed;
  bottom: 3%;
  width: 85%;
  background: #0067a0;
  border: 2px solid #007cb0;
  border-radius: 32px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: white;
}

.scroll-content-modal {
  flex: 1;
  padding-bottom: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portal-modal__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
}

.portal-modal__text {
  text-align: center;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
  display: inline-block;
  color: #024b71;
  white-space: pre-line;
}

.portal-modal__button {
  background: #0067a0;
  border: 2px solid #007cb0;
  color: white;
  padding: 0.5rem;
  width: 100%;
  border-radius: 32px;
  cursor: pointer;
}

.portal-modal__icon {
  margin-bottom: 1rem;
}

.feedback-container {
  border-top: 1px solid #f3f3f3;
  box-shadow: 1px #f3f3f3;
  padding: 1.1rem 0 1.5rem;
  width: 100%;
  align-self: center;
  position: fixed;
  bottom: 0;
  z-index: 9997;
  background: white;
}
