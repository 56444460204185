.wrapper {
  display: flex;
  flex-direction: column;
}

.upperDiv {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 5vh;
  min-height: 50vh;
}

.lowerDiv {
  flex: 1;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  background-color: #e5f1fd;
  min-height: 30vh;
  overflow: hidden;
  padding-top: 20px;
}

.Shape {
  margin: 16px 224px 16.8px 16px;
  object-fit: contain;
}

.mainLogo {
  margin: 16.8px 55.2px 16px 51px;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .sams-logo__container {
    align-self: baseline;
  }
}
